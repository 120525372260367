import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@openbox-app-shared'

const StyledNumberGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  .component--button {
    aspect-ratio: 1 / 1;
    font-size: 200%;
  }
`

const rawNumbers = [7, 8, 9, 4, 5, 6, 1, 2, 3, 0].map(n => n.toString())

const getNumbers = () => {
  return [
    ...
    rawNumbers.map(n => {
      return {
        id: n,
        name: n,
        isSecondary: true
      }
    })
  ]
}
const allNumbers = getNumbers()

export default function NumberGrid ({ onChange, onDone, onGoBack, okButtonEnabled = true }) {
  const numbers = [
    ...allNumbers,
    {
      id: 'go-back',
      name: '←',
      isSecondary: true
    },
    ...(onDone ?
      [
        {
          id: 'done',
          name: '→',
          disabled: !okButtonEnabled
        }
      ] :
      []
    )
  ]
  useEffect(
    () => {
      function listener (e) {
        e.preventDefault()
        // log('[NumberGrid]', e.key, typeof e.key)
        if (rawNumbers.includes(e.key)) {
          onChange(e.key)
        }
        e.key === 'Enter' && onDone && onDone()
        e.key === 'Backspace' && onGoBack()
      }
      document.addEventListener('keydown', listener)
      return () => {
        document.removeEventListener('keydown', listener)
      }
    }
  )
  return (
    <StyledNumberGrid>
      {numbers.map(p => {
        return (
          <Button
            key={`d--${p.id}`}
            isSecondary={p.isSecondary}
            onClick={() => {
              p.id.length === 1 && onChange(p.id)
              p.id === 'done' && onDone()
              p.id === 'go-back' && onGoBack()
            }}
            disabled={p.disabled}
            className={p.className}
          >
            {p.name}
          </Button>
        )
      })}
    </StyledNumberGrid>
  )
}
NumberGrid.propTypes = {
  onChange: PropTypes.func,
  onDone: PropTypes.func,
  onGoBack: PropTypes.func,
  showDoneButton: PropTypes.bool,
  okButtonEnabled: PropTypes.bool
}
