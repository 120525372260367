import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { List, ListItem, Loading, Button } from '@openbox-app-shared'
import dashboardIcons from '../icons'
import Box from './Box'
import { dispatch, subscribe } from '../redux'

import _ from '../_'

const StyledComponent = styled.div`
  .component--button + .component--list {
    margin-top: 1rem;
  }
`

function onAction (shortLinks, id, action) {
  const entity = shortLinks.find(_ => _.id === id)
  action === 'copy' && (() => {
    window.sticky.shortLinks.share(entity.getUrl)
  })()
  action === 'delete' && (() => {
    dispatch('SURE_DELETE', { why: 'shortLinks', entity })
  })()
}

export default class ShortLinks extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async reload (force) {
    const shortLinks = await window.sticky.shortLinks.getAll(force)
    this.setState({
      shortLinks
    })
  }

  async reloadWrapper (force) {
    try {
      await this.reload(force)
    } catch (e) {
      dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
    }
  }

  async componentDidMount () {
    this.subscriptions = [
      subscribe(
        'CREATE_SHORT_LINK_GOOD',
        ({ shortLink }) => {
          window.sticky.shortLinks.share(shortLink.getUrl)
          this.reloadWrapper(true)
        }
      ),
      subscribe(
        'SURE_DELETE_GOOD',
        async ({ why, entity }) =>  {
          why === 'shortLinks' && (async () => {
            try {
              await window.sticky.shortLinks.remove(entity)
              this.reload()
            } catch ({ message }) {
              window.sticky.applications.blocks.showError(message)
            }
          })()
        }
      )
    ]
    await this.reload()
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach((s) => s())
  }

  render () {
    const { shortLinks } = this.state
    const { stuffToDo } = this.props
    return (
      <StyledComponent>
        <Box>
          {stuffToDo.includes('add') && <Button
            InlineIcon={dashboardIcons.add}
            onClick={() => {
              dispatch('CREATE_SHORT_LINK')
            }}
          >
            Link
          </Button>}
          {!shortLinks && (
            <Loading />
          )}
          {shortLinks && (
            <>
              <List>
                {shortLinks.map(_ => {
                  const tags = []
                  const isPaid = _.internalTags.includes('paymentPaid')
                  _.internalPaymentId && tags.push({ name: <><code>#{_.internalPaymentId.substring(0, 8).toUpperCase()}</code> →</>, color: isPaid ? '#40cf7a' : '#A9B7C6', foregroundColor: '#ffffff', InlineIcon: isPaid ? dashboardIcons.check : undefined, goTo: `/me/payments/${_.internalPaymentId}` })
                  stuffToDo.includes('opened') && _.internalTags.includes('used') && tags.push({ name: 'Opened', color: '#fd9644', foregroundColor: '#ffffff', InlineIcon: dashboardIcons.events })
                  stuffToDo.includes('application') && _.application && tags.push({ name: _.application.name, color: '#F0F1F3', foregroundColor: '#1A1F35', icon: _.application.baseIcon })

                  // const rightSideChildren = <TagChooser
                  //   all={currentRhsC}
                  //   set={window.sticky.newPatchableSet(currentRhsC.map(_ => _.id))}
                  //   can={false}
                  // />
                  return (
                    <ListItem
                      id={_.id}
                      key={_.id}
                      InlineIcon={dashboardIcons.link}
                      actions={['copy', 'delete']}
                      onAction={(...args) => onAction(shortLinks, ...args)}
                      tags={tags}
                      target='_blank'
                      // rightSideChildren={rightSideChildren}
                    >
                      {_.name}
                      {/*  <code>{_.customSlug}</code> ］ */}
                    </ListItem>
                  )
                })}
              </List>
            </>
          )}
        </Box>
      </StyledComponent>
    )
  }
}

ShortLinks.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  stuffToDo: PropTypes.arrayOf(PropTypes.string)
}
