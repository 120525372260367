import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, Button, Price, Modal } from '@openbox-app-shared'

import { dispatch, subscribe } from '../../../../redux'
import { log } from '../../../../log'
import dashboardIcons from '../../../../icons'
import classnames from 'classnames'
import H1 from '../../../../components/H1'

const StyledSummary = styled.div`
  .grid {
    display: grid;
    grid-gap: 1rem;
    .component--button {
      width: 100%;
    }
  }
  .grid.grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid + .grid, .grid + div, div + .grid {
    margin-top: 1rem;
  }
  .headline {
    .total {
      display: block;
      text-align: center;
      font-size: 2.5rem;
      height: 56px;
      line-height: 56px;
      color: var(--v2-color-on-white, #1A1F35);
    }
  }
  .method-1 {
    .component--button {
      width: 100%;
      border-radius: 6px;
      min-height: 4rem;
      font-size: 140%;
      > strong {
        margin-top: 0;
      }
      > svg {
        width: 2rem;
        height: 2rem;
      }
      > strong {
        line-height: 2rem !important;
      }
    }
  }
  .method-2 {
    .component--button {
      width: 100%;
      height: 104px;
      border-radius: 6px;
      > strong {
        margin-top: 0.5rem;
      }
    }
  }
  h1 {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2.5rem;
    color: var(--v2-color-on-white, #1A1F35);
  }
`

export default function Summary ({
  user,
  userPreferences,
  payment,
  onAction,
  things,
  thingsVirtual,
  applicationsMoto,
  applicationsNotMoto
}) {
  const [statePay, setStatePay] = useState()
  const [doDestroyPaymentWhenClosingModal, setDoDestroyPaymentWhenClosingModal] = useState(false)
  let [lastPayThing, setLastPayThing] = useState()
  const [hasMadePreDecision, setHasMadePreDecision] = useState(false)

  const hasThings = things.length > 0
  const isEveryThingVirtual = things.length === thingsVirtual.length

  const { currency, total } = payment || {}

  const { posCashCard = true, posCashCardCard = false } = userPreferences
  const canCard = payment.total > 0 && applicationsMoto.length > 0
  const canCash = payment.total > 0 && posCashCard
  const canGATEWAY_CARD = posCashCardCard
  const canCardCashArray = [canCard, canCash, canGATEWAY_CARD].filter(_ => _)

  function inlineReset () {
    setDoDestroyPaymentWhenClosingModal(false)
  }

  useEffect(() => {
    if (!hasMadePreDecision && statePay && applicationsNotMoto.length > 0 && applicationsMoto.length === 0 && !hasThings && !canCard && !canCash && !user.can('has-printer')) {
      onLink()
      setHasMadePreDecision(true)
    }
    if (!hasMadePreDecision && statePay && applicationsNotMoto.length === 0 && applicationsMoto.length > 0 && !hasThings && canCard && !canCash && !user.can('has-printer')) {
      onCard()
      setHasMadePreDecision(true)
    }

    const subscriptions = [
      subscribe('HOW_TO_PAY_READY_1', () => {
        setStatePay(true)
        setDoDestroyPaymentWhenClosingModal(true)
      }),
      subscribe('HOW_TO_PAY_READY_2', () => {
        setStatePay(true)
        inlineReset()
      }),
      subscribe(
        'SHOW_MESSAGE_GOOD',
        ({ why }) => {
          why && why.startsWith('GATEWAY--') && (() => {
            why = why.substring('GATEWAY--'.length)
            setStatePay(false)
            inlineReset()
            onAction('pay', { toDo: why })
          })()
        }
      ),
      subscribe(
        'GET_PRICE_GOOD',
        ({ why, price }) => {
          why.startsWith('GATEWAY--') && (() => {
            const delta = price - total
            if (delta === 0) {
              why = why.substring('GATEWAY--'.length)
              setStatePay(false)
              inlineReset()
              onAction('pay', { toDo: why })
              return
            }
            dispatch(
              'SHOW_MESSAGE',
              {
                message: <p className='openbox--pulsing-2'>Now give <strong><Price price={delta} currency={currency} /></strong></p>,
                why,
                css: `
                  p {
                    font-size: 120%;
                    line-height: 1.5rem;
                  }
                `
              }
            )
          })()
        }
      ),
      subscribe(
        'PAY_GO_TO_ON_CHANGE',
        ({ k, v }) => {
          onAction('patch', { k, v }, false)
        }
      ),
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing, why }) => {
          if (!thing) {
            return
          }
          why === 'epos--pay--thing' && (() => {
            lastPayThing = thing
            setLastPayThing(lastPayThing)
            dispatch('CHOOSE_APPLICATIONS', { why: 'epos--pay--thing', applicationIds: applicationsNotMoto.map(_ => _.id) })
          })()
          why === 'epos--GATEWAY_LATER' && (() => {
            onAction('epos--GATEWAY_LATER', { thing })
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === 'epos--extra' && onAction('extra', { string })
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        ({ why, applications }) => {
          log('[CHOOSE_APPLICATIONS_GOOD]', { why, applications, lastPayThing })
          const newApplicationId = applications[0].id
          why === 'epos--onLink' && (() => {
            payment.application = applications[0]
            onAction('patch', { k: 'newStatusDoneDontSet', v: true }, false)
            dispatch('PAY_GO_TO', { why: 'epos', payment, userPreferences })
          })()
          why === 'epos--pay--thing' && (() => {
            onAction('pay', { toDo: 'sticky', thing: lastPayThing, newApplicationId })
          })()
          why === 'epos--moto' && (() => {
            dispatch('APPLICATION_FOR_PAY', { why: 'epos--moto', paymentId: payment.id, applicationId: newApplicationId })
          })()
        }
      ),
      subscribe(
        'APPLICATION_FOR_PAY_GOOD',
        ({ why, extra, paymentId }) => {
          why === 'epos--moto' && (() => {
            setStatePay(false)
            inlineReset()
            onAction('addPayment')
          })()
        }
      ),
      subscribe(
        'PAY_WAIT_ORIGIN_GOOD',
        () => {
          setStatePay(false)
          inlineReset()
          onAction('addPayment')
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const onLink = () => {
    log('[onLink]')
    dispatch('CHOOSE_APPLICATIONS', { why: 'epos--onLink', applicationIds: applicationsNotMoto.map(_ => _.id) })
  }
  const onCard = () => {
    dispatch('CHOOSE_APPLICATIONS', { why: 'epos--moto', applicationIds: applicationsMoto.map(_ => _.id) })
  }
  const onCardMachine = () => {
    dispatch('SHOW_MESSAGE', { why: 'GATEWAY--GATEWAY_CARD', message: <><p><strong>The total is <Price price={total} currency={currency} /></strong></p><p>Was the payment successful?</p></>, canBeGooded: 'Yes', canBeBadded: '← Go back' })
  }
  const onCash = () => {
    const arg = {
      total: payment.total,
      currency: payment.currency
    }
    log('[onCash]', { arg })
    dispatch('GET_PRICE', { why: 'GATEWAY--GATEWAY_CASH', hint: <><strong>The total is <Price price={arg.total} currency={arg.currency} /></strong><strong>How much have you taken?</strong></>, price: 0 })
  }
  const onPayThing = () => {
    dispatch('CHOOSE_THING', { why: 'epos--pay--thing', hint: 'Which sticky should take the payment?' })
  }
  const onMove = () => {
    dispatch('CHOOSE_THING', { why: 'epos--GATEWAY_LATER', hint: 'Which sticky should this be assigned to?' })
  }

  const paymentCart = payment && payment.cart.get()
  const canPayOrTab = (() => {
    if (!payment) {
      return true
    }
    if (paymentCart.length > 0) {
      return false
    }
    if (payment.total === 0) {
      return true
    }
    return false
  })()

  log('[Epos->Summary]', { paymentCart, canCard, canCash, canGATEWAY_CARD, canCardCashArray })

  return (
    <StyledSummary>
      {statePay && <Modal
        onClose={() => {
          setStatePay(false)
          setHasMadePreDecision(false)
          if (doDestroyPaymentWhenClosingModal) {
            onAction('v2VoidThenAddPayment')
            inlineReset()
          } else {
            onAction('v2ChangeTotal')
          }
        }}
      >
        <H1><Price currency={currency} price={total} /></H1>
        <div className='grid grid-1'>
          {(hasThings && applicationsNotMoto.length > 0) && <div className='method-2 sticky'>
            <Button
              bigIcon
              InlineIcon={!isEveryThingVirtual ? dashboardIcons.stickypay : undefined}
              icon={isEveryThingVirtual ? window.sticky.applications.blocks.getIcon('7078f355-74cf-4c5e-b713-02de63c44610') : undefined}
              onClick={onPayThing}
            >
              {isEveryThingVirtual ? 'QR code' : 'Tap phone'}
            </Button>
          </div>}
          {applicationsNotMoto.length > 0 && <div className={!(hasThings && applicationsNotMoto.length > 0) ? 'method-2' : undefined}>
            <Button
              bigIcon={!(hasThings && applicationsNotMoto.length > 0)}
              InlineIcon={dashboardIcons.link}
              onClick={onLink}
              isSecondary={(hasThings && applicationsNotMoto.length > 0)}
            >
              Send link
            </Button>
          </div>}
        </div>
        {canCardCashArray.length > 0 && <div className='grid grid-1'>
          {canCash && <div className='GATEWAY_CASH'>
            <Button
              onClick={onCash}
              isSecondary
              inlineIconString='<svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m385.757098 69 34.565902 77.632 36.515852-7.760452 17.577148 82.698452 37.584.000272v220.148754l-421.09-.000026-1.3358874.284087-.0371126-.175087-89.537-201.089952zm107.163902 287.268-311.664 66.247 311.664645.000631zm-26.819-82.056-292.83 130.367 306.684294-65.186617zm26.819645-33.460212-14.428645-.000788 14.428 67.875zm-50.756179-79.151272-13.865466 2.946484 25.39 57.023h1.222z" fill="currentColor" fill-rule="evenodd"/></svg>'
            >
              {window.sticky.Stickypay.GATEWAYS.find(_ => _.id === 'GATEWAY_CASH').name}
            </Button>
          </div>}
          {canCard && <div className='GATEWAY_CARD'>
            <Button
              onClick={onCard}
              isSecondary
              inlineIconString='<svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="m0 142.153846h512v71.153846h-512z" fill="currentColor" fill-rule="nonzero"/><path d="m455.111111 270.230769v85.384616h-398.2222221v-85.384616zm-331.826763 24c-26.2399188 0-42.9372236 21.300454-43.6363494 22.271599-2.9690151 4.115806-2.0882981 9.905682 1.9521046 12.930106 1.6161611 1.211619 3.4956294 1.794307 5.3660181 1.794307 2.7874239 0 5.5476091-1.313359 7.3181227-3.782842.1180342-.166483 12.320958-16.453977 29.190775-16.453977.154352 0-.21791 1.220869-.190671 1.738813.690046 12.254929 7.290884 18.498006 18.159114 18.498006 9.933035 0 17.80501-5.096201 24.750871-9.591216 6.582679-4.263791 11.422083-6.687029 16.3886-7.066238 1.162183 5.46616 6.954941 16.657454 22.417426 16.657454 9.642489 0 22.580857-3.634858 31.74213-7.297464 7.744862-3.089167 15.053905-6.002602 21.618424-6.002602 19.231954 0 27.910064 7.355813 29.864369 9.291387l.122874.123422c.187575.191205.289447.308667.311238.33364 1.85223 2.413989 4.630574 3.634858 7.417998 3.551617 1.870389-.055494 3.740777-.702925 5.3297-1.970038 3.949607-3.144661 4.639653-8.953035 1.552604-12.976351-1.325615-1.720314-13.837244-16.842434-44.607862-16.842434-9.996592 0-19.275899 3.699601-28.246501 7.288214-8.661897 3.459127-16.842578 6.733274-24.287814 6.039599-3.913289-.360711-5.175348-1.20237-5.238904-1.20237-.154353-.332965-.326865-1.387351-.444899-2.090275-.472137-2.820946-1.706956-10.29414-9.61525-12.356668-14.500052-3.782842-25.322884 3.255649-34.048338 8.897541-5.257063 3.412882-9.84224 6.372563-14.254904 6.687029l-.063557-1.008141c-.272387-4.744739-1.280217-17.462118-18.867319-17.462118z" fill="currentColor" fill-rule="nonzero"/><rect height="350" rx="40" stroke="currentColor" stroke-width="20" width="492" x="10" y="81"/></g></svg>'
            >
              Enter card
            </Button>
          </div>}
          {canGATEWAY_CARD && <div className='GATEWAY_CARD'>
            <Button
              onClick={onCardMachine}
              isSecondary
              icon={`${window.sticky.cdn}/gateways/icon--GATEWAY_CARD.svg`}
            >
              Machine
            </Button>
          </div>}
        </div>}
        {user.can('has-printer') && (
          <>
            <hr />
            <div className='grid method-3'>
              <Button
                InlineIcon={dashboardIcons.printer}
                isSecondary
                bigIcon
                onClick={() => onAction('print')}
              >
                Print
              </Button>
            </div>
          </>
        )}
        <hr />
        <div className='grid method-3'>
          <Button
            InlineIcon={dashboardIcons.add}
            onClick={() => {
              setStatePay(false)
              inlineReset()
              onAction('addPayment')
            }}
          >
            New payment
          </Button>
          {user.can('has-printer-local') && <Button
            InlineIcon={dashboardIcons.printer}
            isSecondary
            onClick={() => {
              window.sticky.popUpIframe({
                src: `${window.location.origin}/me/payments/${payment.id}?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}&autoUi=printNow`,
                maxWidth: '376px',
                maxHeight: '680px',
                showBlocker: false
              })
            }}
          >
            Print
          </Button>}
        </div>
      </Modal>}
      <div className='headline'>
        <strong>
          {<Price className='total total-1' currency={currency} price={total} />}
        </strong>
      </div>
      <div className={`grid grid-${!isEveryThingVirtual ? 2 : 1} method-1`}>
        <Button
          onClick={() => {
            setStatePay(true)
          }}
          inlineIconString={window.sticky.internals.icons.get('check')}
          disabled={canPayOrTab}
          className={classnames({ 'openbox--pulsing-1': payment && payment.total > 0 })}
        >
          Pay now
        </Button>
        {!isEveryThingVirtual && <Button
          onClick={onMove}
          isSecondary
          inlineIconString={window.sticky.internals.icons.get('time')}
          disabled={canPayOrTab}
        >
          Pay later
        </Button>}
      </div>
    </StyledSummary>
  )
}

Summary.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  payment: PropTypes.object,
  onAction: PropTypes.func.isRequired,
  canMove: PropTypes.bool,
  things: PropTypes.array,
  thingsVirtual: PropTypes.array,
  thingsNotVirtual: PropTypes.array,
  applicationsMoto: PropTypes.array,
  applicationsNotMoto: PropTypes.array,
  onDiscount: PropTypes.func
}
