import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

const StyledBanner = styled.div`
  padding: 0.75rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  pre {
    display: inline;
  }
  p, ul {
    font-size: 90%;
    line-height: 1.25rem;
  }
  ul li {
    margin-left: 1rem;
  }
  p a, .component--h2 {
    color: inherit;
  }
  > * {
    margin: 0;
  }
  > * + * {
    margin-top: 0.5rem;
  }
  .component--h2 + p {
    margin-top: 0;
  }
  &.mood--very-bad {
    background-color: #F0003C;
    color: white;
    .component--button {
      color: white;
    }
  }
  &.mood--bad {
    background-color: #FFFAD8;
    color: #B41400;
    .component--button {
      color: #B41400;
    }
  }
  &.mood--good {
    background-color: #D1F8C3;
    color: #006B11;
    .component--button.is-secondary {
      color: #006B11;
    }
  }
`

const Banner = ({ children, className, style = {}, mood = 'bad' }) => {
  return (
    <StyledBanner className={classnames('component--banner', className, `mood--${mood}`)} style={style}>
      {children}
    </StyledBanner>
  )
}

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  mood: PropTypes.string
}

export default Banner
