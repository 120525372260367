import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import iconsComplexity from '../icons/complexity'
import iconActionEdit from '../icons/inverted/files/action-edit.svg'

import Button from './Button'

const styledTagCss = `
  text-decoration: none;
  &.is-editable {
    padding-right: 2rem;
  }
  &.is-clickable {
    cursor: default;
  }
  &.is-squeezed {
    height: 1.125rem;
    line-height: 1rem;
    padding: 0.125rem 0.25rem 0 0.25rem;
    font-size: 80%;
  }
  height: 2rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 6px;
  font-size: 90%;
  color: white;
  background-color: #6C7A89;
  position: relative;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  background-position: 0.375rem 0.375rem;
  > svg {
    position: absolute;
    top: 0.375rem;
    left: 0.375rem;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
  }
  .component--button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    width: 1rem !important;
    height: 1rem !important;
    background-color: transparent;
    > img {
      width: 1rem;
      height: 1rem;
    }
  }
`

const StyledTag = styled.strong`${styledTagCss}`
const StyledGoTo = styled.a`${styledTagCss}`
export function Tag ({ tag }) {
  const { icon, InlineIcon, onEdit, onClick, goTo, isSqueezed } = tag
  const hasIcon = icon || InlineIcon
  const WhichComponent = goTo ? StyledGoTo : StyledTag
  return (
    <WhichComponent
      className={classnames('component--tag', { 'make-me-strong': goTo, 'is-editable': onEdit, 'is-clickable': onClick, 'is-squeezed': isSqueezed })}
      style={{ backgroundColor: tag.color, color: tag.foregroundColor, backgroundImage: icon ? `url(${icon})` : undefined, paddingLeft: hasIcon ? '2rem' : undefined }}
      onClick={onClick}
      href={goTo}
    >
      {InlineIcon && <InlineIcon />}
      {tag.name}
      {onEdit && <Button icon={iconActionEdit} onClick={onEdit} />}
    </WhichComponent>
  )
}
Tag.propTypes = {
  tag: PropTypes.shape({
    icon: PropTypes.string,
    InlineIcon: PropTypes.node,
    onEdit: PropTypes.func,
    onClick: PropTypes.func,
    goTo: PropTypes.string,
    isSqueezed: PropTypes.bool,
    color: PropTypes.string,
    foregroundColor: PropTypes.string,
    name: PropTypes.node
  })
}

const StyledTags = styled.div`
  .component--tag {
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
    vertical-align: top;
    width: fit-content;
  }
`
export function Tags ({ children }) {
  return (
    <StyledTags className='component--tags'>{children}</StyledTags>
  )
}
Tags.propTypes = {
  children: PropTypes.node
}

class MagicTag {
  constructor (object) {
    Object.keys(object).forEach(jk => {
      this[jk] = object[jk]
    })
  }

  canShow (user) {
    return true
  }
}

// class MagicTagPrint extends MagicTag {
//   canShow (user) {
//     return user.can('has-printer')
//   }
// }

class MagicTagLinearOperatorView extends MagicTag {
  canShow (user) {
    return user.can('show-linear-operator-view')
  }
}

export const tags = [
  new MagicTag({
    id: 'vat--20',
    name: 'Tax: 20%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--0',
    name: 'Tax: 0%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--no',
    name: 'Tax: No',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--21',
    name: 'Tax: 21%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--19',
    name: 'Tax: 19%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--15',
    name: 'Tax: 15%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--125',
    name: 'Tax: 12.5%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTag({
    id: 'vat--5',
    name: 'Tax: 5%',
    color: '#1A1F35',
    doReport: true
  }),
  new MagicTagLinearOperatorView({
    id: 'complexity--1',
    name: 'Easy',
    color: '#A9B7C6',
    doesAFederatedUserCare: true,
    doReport: true,
    icon: iconsComplexity.easy
  }),
  new MagicTagLinearOperatorView({
    id: 'complexity--2',
    name: 'Regular',
    color: '#A9B7C6',
    doesAFederatedUserCare: true,
    doReport: true,
    icon: iconsComplexity.regular
  }),
  new MagicTagLinearOperatorView({
    id: 'complexity--3',
    name: 'Hard',
    color: '#A9B7C6',
    doesAFederatedUserCare: true,
    doReport: true,
    icon: iconsComplexity.hard
  }),
  new MagicTagLinearOperatorView({
    id: 'complexity--4',
    name: 'Expert',
    color: '#A9B7C6',
    doesAFederatedUserCare: true,
    doReport: true,
    icon: iconsComplexity.expert
  }),
  new MagicTag({
    id: 'dry',
    name: 'Food',
    color: '#A9B7C6',
    doReport: true
  }),
  new MagicTag({
    id: 'wet--alcohol',
    name: 'Alcohol',
    color: '#A9B7C6',
    doReport: true
  }),
  new MagicTag({
    id: 'wet--soft',
    name: 'Not alcohol',
    color: '#A9B7C6',
    doReport: true
  }),

  new MagicTag({
    id: 'gluten-free-really',
    name: 'Gluten free',
    color: '#a67b50',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'gluten-free',
    name: 'Non-gluten-containing ingredients',
    color: '#a67b50',
    doesAConsumerCare: true
  }),

  new MagicTag({
    id: 'allergy--gluten',
    name: 'Contains gluten',
    color: '#a67b50',
    doesAConsumerCare: true
  }),

  new MagicTag({
    id: 'vegan',
    name: 'Vegan',
    color: '#A3CB38',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'vegetarian',
    name: 'Vegetarian',
    color: '#009432',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'halal',
    name: 'Halal',
    color: '#009432',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'kosher',
    name: 'Kosher',
    color: '#8D764F',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--soy',
    name: 'Soy',
    color: '#8BA251',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--celery',
    name: 'Celery',
    color: '#2ed573',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--eggs',
    name: 'Eggs',
    color: '#feca57',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--fish',
    name: 'Fish',
    color: '#ff6b81',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--mustard',
    name: 'Mustard',
    color: '#eccc68',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--lupin',
    name: 'Lupin',
    color: '#ba946f',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--milk',
    name: 'Milk',
    color: '#54a0ff',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--nuts',
    name: 'Nuts',
    color: '#ba946f',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--peanuts',
    name: 'Peanuts',
    color: '#ba946f',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--sesame',
    name: 'Sesame',
    color: '#ba946f',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--molluscs',
    name: 'Molluscs',
    color: '#474787',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--crustaceans',
    name: 'Crustaceans',
    color: '#ff4d4d',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--sulphites',
    name: 'Sulphites',
    color: '#b33939',
    doesAConsumerCare: true
  }),
  new MagicTag({
    id: 'allergy--spicy',
    name: 'Spicy',
    color: '#f53b57',
    doesAConsumerCare: true
  })
]
