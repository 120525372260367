import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Price, Time, Tags, Tag, T, TimeDifference, InlineHtml, isUuid } from '@openbox-app-shared'
import dashboardIcons from '../icons'

function PaymentText ({ text, color }) {
  return <span style={{color: color}}>{text}</span>
}
PaymentText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string
}

const StyledPaymentSummary = styled.div``

export function PaymentSummary ({ isByDay, payment, showTime = true, showGateway = true, showId = false }) {
  const tags = []
  const timeProps = { isByDay }

  payment.isSubscription && tags.push(<Tag tag={{ id: 'is-subscription', name: <><Price currency={payment.currency} price={payment.totalCpa} />/<T>{payment.cpaPeriod}</T></>, color: '#1cab60' }} />)

  showTime && typeof payment.sessionPaidAt !== 'number' && payment.gateway === 'GATEWAY_LATER' && tags.push(<Tag tag={{ id: 'status--GATEWAY_LATER', name: <><Price currency={payment.currency} price={payment.total} /> (<Time {...timeProps} time={payment.updatedAt} />)</>, color: '#90a4ae' }} key='status--GATEWAY_LATER' />)

  showTime && typeof payment.sessionPaidAt === 'number' && tags.push(<Tag tag={{ id: 'status--paid', name: <>Paid <Price currency={payment.currency} price={payment.total} /> (<Time {...timeProps} time={payment.sessionPaidAt} />)</>, color: '#40cf7a' }} key='status--paid' />)
  typeof payment.sessionFailedAt === 'number' && tags.push(<Tag tag={{ id: 'status--failed', name: <>Failed (<Price currency={payment.currency} price={payment.total} />; <Time {...timeProps} time={payment.sessionFailedAt} />)</>, color: '#ff3838' }} key='status--failed' />)
  typeof payment.sessionFailedAtConnection === 'number' && tags.push(<Tag tag={{ id: 'status--failed-connection', name: <>Failed due to connection (<Price currency={payment.currency} price={payment.total} />; <Time {...timeProps} time={payment.sessionFailedAtConnection} />)</>, color: '#b33939' }} key='status--failed-connection' />)
  typeof payment.refundedAt === 'number' && tags.push(<Tag tag={{ id: 'status--refunded', name: <>Refunded <Price currency={payment.currency} price={payment.refundedTotal} /> (<Time {...timeProps} time={payment.refundedAt} />)</>, color: '#ff3838' }} key='status--refunded' />)

  const gateway = payment && showGateway && window.sticky.Stickypay.getGateway(payment.gateway)
  gateway && tags.push(<Tag tag={{ id: 'how-paid--gateway', name: gateway.name, color: '#90a4ae' }} key='how-paid--gateway' />)

  payment.newStatusDone === true && tags.push(<Tag tag={{ id: 'newStatusDone', name: 'Complete', color: '#1A1F35' }} key='newStatusDone' />)
  payment.stickypayIsCorporate && tags.push(<Tag tag={{ id: 'stickypayIsCorporate', name: 'Corporate/Amex', color: '#90a4ae' }} key='stickypayIsCorporate' />)

  showId && tags.push(<Tag tag={{ id: 'how-paid--id', name: payment.consumerIdentifier, color: '#90a4ae' }} key='how-paid--id' />)

  return (
    <StyledPaymentSummary className='component--payment-summary'>
      <Tags>{tags}</Tags>
    </StyledPaymentSummary>
  )
}
PaymentSummary.propTypes = {
  isByDay: PropTypes.bool,
  payment: PropTypes.object.isRequired,
  showTime: PropTypes.bool,
  showGateway: PropTypes.bool,
  showId: PropTypes.bool
}

const StyledPaymentHeadlines = styled.div`
  > * {
    line-height: 1.5rem;
    font-size: 110%;
  }
  > * + * {
    margin-top: 0.75rem;
  }
  .with-icon {
    > svg {
      float: left;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
    > span, > strong {
      line-height: 1.5rem;
    }
  }
  .time > * {
    color: #EA2027;
  }
  .collection-time {
    border: 2px solid #ff8c00;
    color: #ff8c00;
    padding: 0.5rem;
    border-radius: 6px;
    width: fit-content;
  }
  .thing {
    border: 2px solid #1A1F35;
    color: #1A1F35;
    padding: 0.5rem;
    border-radius: 6px;
    width: fit-content;
  }
  .name > * {
    color: #6c5ce7;
  }
  @media screen {
    .fixed-time {
      display: none;
    }
  }
`

export function PaymentHeadlines ({ user, entity }) {
  console.warn('xxx entity.getTimeDone()', entity.getTimeDone())
  return (
    <StyledPaymentHeadlines className='component--payment-headlines'>
      <h1 className='clearfix with-icon time print-hide'>
        <InlineHtml html={window.sticky.internals.icons.get('time')} />
        <strong><TimeDifference time={entity.getTimeDone()} timezone={user.timezone} fallbackToJustNow /></strong>
      </h1>
      <h1 className='clearfix with-icon time fixed-time'>
        <InlineHtml html={window.sticky.internals.icons.get('time')} />
        <strong><Time time={entity.getTimeDone()} timezone={user.timezone} showTimeAsFixed /></strong>
      </h1>
      {typeof entity.collectionTime === 'string' && (
        <h1 className='clearfix with-icon collection-time'>
          <InlineHtml html={window.sticky.internals.icons.get('collect')} />
          <strong>{entity.collectionTime}</strong>
        </h1>
      )}
      {entity.thing && <h1 className='clearfix with-icon thing'><dashboardIcons.thing /><strong>{entity.thing.name}</strong></h1>}
      {entity.name && (
        <h1 className='clearfix with-icon name'>
          <InlineHtml html={window.sticky.internals.icons.get('person')} />
          <strong>{entity.name}</strong>
        </h1>
      )}
      {entity.userPaymentId && !isUuid(entity.userPaymentId) && (
        <h1 className='clearfix with-icon name'>
          <InlineHtml html={window.sticky.internals.icons.get('person')} />
          <strong>{entity.userPaymentId}</strong>
        </h1>
      )}
      <h1 className='clearfix with-icon'><dashboardIcons.payment /><Price price={entity.total} currency={entity.currency} /></h1>
      {entity.application && <h1 className='clearfix with-icon application print-hide'><dashboardIcons.application /><span>{entity.application.name}</span></h1>}
    </StyledPaymentHeadlines>
  )
}
PaymentHeadlines.propTypes = {
  user: PropTypes.object,
  entity: PropTypes.object
}

const StyledPaymentExtra = styled.p`
  width: fit-content;
  padding: 0.75rem;
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid #EA2027;
  color: #EA2027;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  label {
    margin-bottom: 0.5rem;
  }
`
export function PaymentExtra ({ children }) {
  return (
    <StyledPaymentExtra className='component--payment-extra'>
      {children}
    </StyledPaymentExtra>
  )
}
PaymentExtra.propTypes = {
  children: PropTypes.node
}
