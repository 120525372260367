function getImportantQueryParamaters () {
  const queryObject = new URLSearchParams(window.location.search)
  return {
    logOutRedirectTo: queryObject.get('logOutRedirectTo')
  }
}

export default function getLogOutUrl () {
  const gotMeSync = window.sticky.users.getMeSync()
  const { logOutRedirectTo } = getImportantQueryParamaters()
  const finalLogOutRedirectTo = typeof logOutRedirectTo === 'string' && logOutRedirectTo.startsWith('/') ? logOutRedirectTo : undefined
  const partnerCode = gotMeSync && gotMeSync.partner && gotMeSync.partner.code
  const urlParts = [
    finalLogOutRedirectTo && `logInRedirectTo=${encodeURIComponent(finalLogOutRedirectTo)}`,
    partnerCode && `partnerCode=${partnerCode}`
  ]
    .filter(_ => _)
  return `/log-in${urlParts.length > 0 ? `?${urlParts.join('&')}` : ''}`
}
