import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown, DatePicker, TimePicker } from '@openbox-app-shared'

const dateToUnixTime = date => date.getTime() / 1000

function getDaysInMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

const TIME_PERIOD_TEMPLATE = {
  formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
}

const TIME_PERIODS = [
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'latest',
    name: 'Latest',
    startTime: undefined,
    timeLength: undefined,
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
  },
  {
    id: '---1',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'today',
    name: 'Today (midnight → midnight)',
    startTime: dateToUnixTime((() => {
      const date = new Date()
      date.setHours(0, 0, 0, 0)
      return date
    })()),
    timeLength: 86400,
    formatUnixTime: _ => ((d) => `${d.getHours().toString().padStart(2, '0')}:00`)(new Date(_))
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'yesterday',
    name: 'Yesterday (midnight → midnight)',
    startTime: dateToUnixTime((() => {
      const date = new Date()
      date.setDate(date.getDate() - 1)
      date.setHours(0, 0, 0, 0)
      return date
    })()),
    timeLength: 86400,
    formatUnixTime: _ => ((d) => `${d.getHours().toString().padStart(2, '0')}:00`)(new Date(_))
  },
  {
    id: '---2',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'this-month',
    name: 'This month (1st → now)',
    ...(() => {
      const date = new Date()
      date.setDate(1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * getDaysInMonth(date)
      }
    })(),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'long', day: 'numeric' })
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'last-month',
    name: 'Last month',
    ...(() => {
      const date = new Date()
      date.setMonth(date.getMonth() - 1)
      date.setDate(1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * getDaysInMonth(date)
      }
    })(),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'long', day: 'numeric' })
  },
  {
    id: '---3',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'this-year',
    name: 'This year (1st → now)',
    ...(() => {
      const date = new Date(new Date().getFullYear(), 0, 1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * 365
      }
    })(),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'short' })
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'last-year',
    name: 'Last year',
    ...(() => {
      const date = new Date(new Date().getFullYear() - 1, 0, 1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * 365
      }
    })(),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'short' })
  },
  {
    id: '---4',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'custom',
    name: 'Custom',
    startTime: undefined,
    timeLength: undefined
  }
]

const StyledComponent = styled.div`
  > * {
    display: inline-block;
    vertical-align: top;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  > .component--dropdown {
    width: 12rem;
  }
`

export default function DatePickerBadAss({ user, onChange }) {
  const [timeInDayFrom, setTimeInDayFrom] = useState(0)
  const [timeInDayTo, setTimeInDayTo] = useState(1440)
  const startOfToday = window.sticky.dateTime.getStartOfToday(undefined)
  const endOfToday = window.sticky.dateTime.getEndOfToday(undefined) - 1
  const [customStartTime, setCustomStartTime] = useState(startOfToday)
  const [customEndTime, setCustomEndTime] = useState(endOfToday)
  const [timePeriodId, setTimePeriodId] = useState(TIME_PERIODS[0].id)
  const timePeriod = TIME_PERIODS.find(tp => tp.id === timePeriodId)

  return (
    <StyledComponent className='component--date-picker-bad-ass'>
      <Dropdown
        label='Time period'
        selected={timePeriod.id}
        items={TIME_PERIODS}
        onChoose={_ => {
          const newTp = TIME_PERIODS.find(tp => tp.id === _)
          setTimePeriodId(newTp.id)
          if (newTp.id === 'custom') {
            onChange(customStartTime + timeInDayFrom, customEndTime + timeInDayTo)
          } else {
            setTimeInDayFrom(0)
            setTimeInDayTo(1440)
            onChange(newTp.startTime, newTp.startTime + newTp.timeLength)
          }
        }}
      />
      {timePeriod.id === 'custom' && (
        <>
          <DatePicker
            time={customStartTime}
            label='From'
            onChange={_ => {
              _ = window.sticky.dateTime.getStartOfToday(_, user.timezone) + (timeInDayFrom * 60)
              if (customEndTime < _) {
                setCustomEndTime(window.sticky.dateTime.getEndOfToday(_) - 1)
              } else {
                setCustomEndTime(customEndTime)
              }
              setCustomStartTime(_)
              onChange(_, customEndTime)
            }}
            maxTime={startOfToday}
          />
          <TimePicker
            time={timeInDayFrom}
            label='At'
            timezone={0}
            showInvalid={false}
            onChange={v => {
              setTimeInDayFrom(v)
              const newV = (v * 60)
              onChange(customStartTime + newV, customEndTime - (86400 - (timeInDayTo * 60)))
            }}
          />
          <DatePicker
            time={customEndTime}
            label='To'
            onChange={_ => {
              _ = window.sticky.dateTime.getEndOfToday(_) - 1
              setCustomEndTime(_)
              onChange(customStartTime - (86400 - (timeInDayTo * 60)), _)
            }}
            minTime={customStartTime}
          />
          <TimePicker
            time={timeInDayTo}
            label='At'
            timezone={0}
            showInvalid={false}
            onChange={v => {
              setTimeInDayTo(v)
              const newV = (v * 60)
              onChange(customStartTime + (timeInDayFrom * 60), customEndTime - (86400 - newV))
            }}
          />
        </>
      )}
    </StyledComponent>
  )
}
DatePickerBadAss.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func
}
