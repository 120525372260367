import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet } from '@openbox-app-shared'
import dashboardIcons from '../icons'
import ShortLinks from '../components/ShortLinks'

import MainFrame from '../components/MainFrame'
import User from '../components/User'

import _ from '../_'
import HeaderBar from '../components/HeaderBar'

const StyledRoute = styled.div`
  padding: 1rem;
`

export default class Route extends Component {
  componentDidMount () {
    const { user } = this.props
    if (!user.federatedUserCan('master')) {
      return window.sticky.applications.blocks.showError('You do not have permission to use this.')
    }
  }

  render () {
    const { user, match, autoUi } = this.props
    const shortLinkProps = {
      stuffToDo: [
        !autoUi.includes('noApplicationLabel') && 'application',
        !autoUi.includes('noOpenedLabel') && 'opened',
        !autoUi.includes('noAdd') && 'add'
      ].filter(_ => _)
    }
    return (
      <StyledRoute>
        <CustomHelmet title={`Links | ${user.name}`} />
        <MainFrame
          user={user}
          autoUi={autoUi}
          aside={<>
            <User user={user} whichPart={match.path} autoUi={this.props.autoUi} />
          </>}
          main={
            <>
              <HeaderBar text='Links' Icon={dashboardIcons.link} user={user} />
              <ShortLinks {...shortLinkProps} />
            </>
          }
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
