function getImportantQueryParamaters () {
  const queryObject = new URLSearchParams(window.location.search)
  return {
    logInRedirectTo: queryObject.get('logInRedirectTo'),
    partnerCode: queryObject.get('partnerCode')
  }
}

export function getLogInUrl () {
  const { logInRedirectTo } = getImportantQueryParamaters()
  return typeof logInRedirectTo === 'string' && logInRedirectTo.startsWith('/') ? logInRedirectTo : undefined
}

export function getLogInUrlWrapper () {
  const { partnerCode } = getImportantQueryParamaters()
  const logInRedirectTo = getLogInUrl()
  const urlParts = [
    logInRedirectTo && `logInRedirectTo=${encodeURIComponent(logInRedirectTo)}`,
    partnerCode && `partnerCode=${partnerCode}`
  ]
    .filter(_ => _)
  return {
    logInRedirectTo: `/log-in${urlParts.length > 0 ? `?${urlParts.join('&')}` : ''}`,
    partnerCode
  }
}
