import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, Details, Loading, Dropdown, Button, LinkButton, Price, Time, Switch } from '@openbox-app-shared'
import { log, error } from '../../log'
import SureButton from '../SureButton'

import Events from './Events'
import Box from '../Box'
import H1 from '../H1'
import EventsTable_SESSION_CART_PAY from './EventsTable_SESSION_CART_PAY'
import Event from './Event'
import EventType from './EventType'
import { subscribe, dispatch } from '../../redux'

import EVENT_TYPES from './EVENT_TYPES'
import dashboardIcons from '../../icons'
import DatePickerBadAss from '../DatePickerBadAss'

const vLoad = (k) => window.sticky.internals.readFromLocalStorage(`dashboard--${k}`)
const vSave = (k, v) => window.sticky.internals.writeToLocalStorage(`dashboard--${k}`, v)

const LEGACY_SDK_EVENT_TYPES = [
  ['---1', { id: '---1', name: '──────────', disabled: true }],

  ['---2', { id: '---2', name: 'Popular', disabled: true }],
  ['PAYMENTS_REPORT_CAPTURE', { id: 'PAYMENTS_REPORT_CAPTURE', name: ' → End of day' }],
  ['LD_V2', { id: 'LD_V2', name: ' → Form' }],

  ['---3', { id: '---3', name: '──────────', disabled: true }],
  ['---4', { id: '---4', name: 'Payment', disabled: true }],
  ['SESSION_CART_PAY', { id: 'SESSION_CART_PAY', name: ' → Success', defaultView: 'table', defaultRestrictiveFilters: true }],
  ['SESSION_CART_PAY_FAIL', { id: 'SESSION_CART_PAY_FAIL', name: ' → Failure', defaultRestrictiveFilters: true }],
  ['SESSION_CART_REFUND', { id: 'SESSION_CART_REFUND', name: ' → Refund', defaultRestrictiveFilters: true }],
  ['SESSION_CART_DISCOUNT', { id: 'SESSION_CART_DISCOUNT', name: ' → Discount', defaultRestrictiveFilters: true }],
  ['SESSION_CART_PAY_ITEM', { id: 'SESSION_CART_PAY_ITEM', name: ' → Paid for product', defaultRestrictiveFilters: true }],
  ['VOID_PAYMENT', { id: 'VOID_PAYMENT', name: ' → Voided payment' }],
  ['VOID_PRODUCT', { id: 'VOID_PRODUCT', name: ' → Voided product' }],
  ['COMP_PAYMENT', { id: 'COMP_PAYMENT', name: ' → Comp' }],

  ['---6', { id: '---6', name: '──────────', disabled: true }],
  ['---7', { id: '---7', name: 'More', disabled: true }],
  ['SESSION_READ', { id: 'SESSION_READ', name: ' → Interaction' }],
  ['CHECK_IN', { id: 'CHECK_IN', name: ' → Check in' }],
  ['CHECK_OUT', { id: 'CHECK_OUT', name: ' → Check out' }],
  ['TO_DO', { id: 'TO_DO', name: ' → To do' }],
  ['ITEM', { id: 'ITEM', name: ' → Item' }],
  ['CONNECTION_GOOD', { id: 'CONNECTION_GOOD', name: ' → Connection success' }],
  ['CONNECTION_BAD', { id: 'CONNECTION_BAD', name: ' → Connection failure' }],
  ['WEBHOOK_FAIL', { id: 'WEBHOOK_FAIL', name: ' → Webhook failed' }]
]

const EVENTS_WHICH_CAN_BE_DOWNLOADED = [
  'SESSION_READ',
  'ITEM',
  'CHECK_IN',
  'CHECK_OUT',
  'SESSION_CART_PAY',
  'SESSION_CART_PAY_ITEM',
  'SESSION_CART_REFUND',
  'LD_V2',
  'TO_DO',

  'CONNECTION_GOOD',
  'CONNECTION_BAD',
  'COMP_PAYMENT'
]
const EVENTS_WHICH_CAN_BE_DELETED = [
  'TO_DO',
  'LD_V2',
  'CHECK_IN',
  'CHECK_OUT',
  'CONNECTION_GOOD',
  'CONNECTION_BAD',
  'WEBHOOK_FAIL'
]

const StyledEventExplorer = styled.div`
  .buttons {
    margin-top: 1.5rem;
    > .component--button {
      display: inline-block;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
  .choose-thing img {
    width: 0.9375rem;
  }
  .summary {
    width: 272px;
    margin-top: 1.5rem;
    .component--h2 {
      margin-bottom: 0.5rem;
    }
    tr {
      td:nth-child(1) {
        text-align: left;
        width: 50%;
      }
      td:nth-child(2) {
        line-height: 1.5rem;
        width: 50%;
      }
    }
  }
  .events {
    position: relative;
    margin-top: 2rem;
    .component--h1 {
      margin-bottom: 1rem;
    }
  }
  .stack-em {
    > * {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 1rem;
    }
    .filter-type {
      width: 12rem;
      margin-right: 1rem;
    }
    .filter-view {
      width: 5rem;
      margin-right: 1rem;
    }
    .bodge-buttons {
      margin-top: 1.25rem;
      > .component--button {
        display: inline-block;
        vertical-align: top;
        margin: 0 1rem 1rem 0;
      }
    }
    .bodge-switches {
      margin-top: 1.5rem;
      > .component--switch {
        display: inline-block;
        vertical-align: top;
        margin: 0 1rem 1rem 0;
      }
    }
  }
  .link-box {
    max-width: 640px;
    position: relative;
    h1 {
      margin-bottom: 1rem;
    }
    > .component--time {
      position: absolute;
      top: 0.75rem;
      right: 1rem;
      line-height: 2rem;
      font-size: 80%;
      color: rgb(108, 122, 137);
    }
    > a {
      display: block;
      button {
        cursor: pointer;
      }
    }
    > p {
      margin-bottom: 10px;
    }
  }
`

function sortObjectByKey(object) {
  return Object.fromEntries(
    Object.entries(object).sort(([, a], [, b]) => b > a ? 1 : -1)
  )
}

function eventsToMap(user, events) {
  const object1 = {}
  events.forEach(e => {
    const f = object1[e.type]
    if (typeof f === 'number') {
      object1[e.type] += 1
    } else {
      object1[e.type] = 1
    }
  })
  const object2 = sortObjectByKey(object1)
  const m = new Map(
    Object.keys(object2)
      .filter(k => EVENT_TYPES.find(t => t[0] === k && t[1].shouldBeInSummary))
      .map(k => {
        return [<EventType key={k} event={{ type: k }} user={user} />, object2[k]]
      })
  )
  return m
}

const getRestrictiveFilter = (type, key) => {
  const defaultReturn = vLoad(key) !== undefined ? (vLoad(key) || undefined) : '!!!null'
  const foundType = LEGACY_SDK_EVENT_TYPES.find(([_]) => _ === type)
  if (!foundType) {
    return defaultReturn
  }
  const { defaultRestrictiveFilters } = foundType[1]
  if (defaultRestrictiveFilters) {
    return false
  }
  return defaultReturn
}

const getThingId = type => getRestrictiveFilter(type, 'ee-thingId')

export default class EventExplorer extends Component {
  constructor(props) {
    super(props)
    const type = (props.queryObject || {}).type || 'all'
    this.state = {
      type,
      thingId: getThingId(type),
      onlyCurrentFederatedUser: vLoad('ee-onlyCurrentFederatedUser') || false,
      view: props.defaultValue || 'feed'
    }
  }

  async refreshLogic({ type, applicationId, thingId, onlyCurrentFederatedUser, view, startTime, endTime }) {
    dispatch('LOADING')

    const user = await window.sticky.users.getMe()
    const things = await window.sticky.things.getAll()
    const applications = await window.sticky.applications.getAll()
    const federatedUsers = await window.sticky.users.federated.getAll()

    const getEventsObject = {
      type: type !== 'all' ? type : undefined,
      applicationId,
      thingId,
      federatedUserId: onlyCurrentFederatedUser ? this.props.currentFederatedUserId : undefined,
      startTime, endTime
    }

    const currentSessionEvents = await this.getEvents(getEventsObject)
    const currentSessionSummary = type === 'all' && eventsToMap(user, currentSessionEvents)
    log('[EventExplorer] [refreshLogic]', { startTime, endTime, user, things, applications, federatedUsers, currentSessionEvents, currentSessionSummary })
    const o = {
      user,
      things,
      applications,
      federatedUsers,
      currentSessionEvents,
      currentSessionSummary,
      type,
      applicationId,
      thingId,
      onlyCurrentFederatedUser,
      view, startTime, endTime
    }
    this.setState(o)
    dispatch('STOP_LOADING')
  }

  componentDidMount() {
    this.subscriptions = [
      subscribe(
        'SESSION_UPDATE',
        ({ why }) => {
          why === 'Event' && this.refreshLogic(this.state)
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        ({ why, applications }) => {
          why === 'EventExplorer' && (() => {
            this.refreshLogic({ ...this.state, applicationId: applications[0] ? applications[0].id : undefined })
          })()
        }
      ),
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing: filteringByThing, why }) => {
          why === 'EventExplorer' && (() => {
            this.refreshLogic({ ...this.state, thingId: filteringByThing ? filteringByThing.id : undefined })
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          const findPayment = async string => {
            log('[FIND_PAYMENT]->GET_INPUT_GOOD->findPayment', { string })
            try {
              const entity = await window.sticky.pay.get(string)
              this.setState({
                foundByIdPayment: entity
              })
            } catch (e) {
              error('[FIND_PAYMENT]->GET_INPUT_GOOD->findPayment', e)
              dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
            }
          }
          why === 'payments--find-by-id' && (async () => {
            findPayment(string.replace(/[^A-Za-z0-9]/g, ''))
          })()
          why === 'payments--find-by-userPaymentId' && (async () => {
            string.length > 0 && findPayment(`userPaymentId:${string}`)
          })()
          why === 'payments--find-by-name' && (async () => {
            string.length > 0 && findPayment(`fpName:${string}`)
          })()
          why === 'payments--find-by-email' && (async () => {
            string.length > 0 && findPayment(`fpEmail:${string}`)
          })()
        }
      )
    ]
    const actualLogic = () => {
      this.refreshLogic(this.state)
    }
    actualLogic()
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  async getEvents(inlineQueryObject) {
    const {
      queryId,
      queryObject: propQueryObject
    } = this.props
    Object.keys(inlineQueryObject)
      .forEach(k => {
        if (inlineQueryObject[k] === undefined) {
          delete inlineQueryObject[k]
        }
      })
    const finalQueryObject = {
      ...propQueryObject,
      ...inlineQueryObject
    }
    log('[EventExplorer] [getEvents]', { queryId, finalQueryObject, propQueryObject, inlineQueryObject })
    const events = await window.sticky.session.events.getAll(queryId, finalQueryObject, true)
    return events
  }

  findPaymentId () {
    dispatch('GET_INPUT', { string: '#', hint: 'Enter an 8 character ID starting with a #:', why: 'payments--find-by-id' })
  }
  findPaymentUserPaymentId () {
    dispatch('GET_INPUT', { string: '', hint: 'Enter an ID:', why: 'payments--find-by-userPaymentId', doValidate: false })
  }
  findPaymentName () {
    dispatch('GET_INPUT', { string: '', hint: 'Enter a name:', why: 'payments--find-by-name', doValidate: false })
  }
  findPaymentEmail () {
    dispatch('GET_INPUT', { string: '', type: 'email', hint: 'Enter an email:', why: 'payments--find-by-email' })
  }

  render() {
    const {
      filterByType = true,
      filterByApplication = true,
      filterByThing = true,
      filterByTime = true,
      queryObject
    } = this.props
    const {
      user,
      things,
      applications,
      federatedUsers,
      currentSessionEvents,
      currentSessionSummary,
      type,
      applicationId,
      thingId,
      onlyCurrentFederatedUser,
      startTime, endTime,
      view,
      foundByIdPayment
    } = this.state
    const filteringByApplication = applicationId && applications && applications.find(a => a.id === applicationId)
    const filteringByThing = thingId && things && things.find(a => a.id === thingId)

    if (!user || !currentSessionEvents) {
      return <Loading />
    }

    const dic = {
      user,
      things,
      applications,
      federatedUsers
    }
    const canFilterView = ['SESSION_CART_PAY'].includes(type)
    return (
      <StyledEventExplorer className='component--event-explorer'>
        <div className='stack-em'>
          {filterByType && <Dropdown
            className='filter-type'
            label='Activity'
            items={
              [
                { id: 'all', name: 'All' },
                // { id: 'CHP_END_OF_DAY', name: 'Sticky card machine "End of day"' },
                ...LEGACY_SDK_EVENT_TYPES
                  .map(([id, et]) => et)
              ]
                .filter(e => e)
            }
            selected={type}
            onChoose={type => {
              const foundType = LEGACY_SDK_EVENT_TYPES.find(([_]) => _ === type)
              const newState = {
                ...this.state,
                type,
                view: (foundType && foundType[1].defaultView) ? foundType[1].defaultView : 'feed'
              }
              this.refreshLogic(newState)
            }}
          />}
          {canFilterView && <Dropdown
            className='filter-view'
            label='View'
            items={
              [
                { id: 'table', name: 'Table' },
                { id: 'feed', name: 'Feed' }
              ]
            }
            selected={view}
            onChoose={view => {
              this.setState({ view })
            }}
          />}
          {filterByTime && <DatePickerBadAss
            user={user}
            onChange={(startTime, endTime) => {
              this.refreshLogic({ ...this.state, startTime, endTime })
            }}
          />}
          {(filterByApplication || filterByThing) && (
            <>
              <div className='bodge-buttons'>
                {filterByApplication && <Button
                  icon={filteringByApplication && filteringByApplication.baseIcon}
                  InlineIcon={!filteringByApplication ? dashboardIcons.application : undefined}
                  isSecondary
                  onClick={() => {
                    dispatch('CHOOSE_APPLICATIONS', { why: 'EventExplorer', allowNone: '(All)' })
                  }}
                >
                  {filteringByApplication ? `Filtering by: ${filteringByApplication.name}` : 'Filter by flow'}
                </Button>}
                {filterByThing && <Button
                  className='choose-thing'
                  icon={filteringByThing && filteringByThing.designUrl}
                  InlineIcon={!filteringByThing ? dashboardIcons.thing : undefined}
                  isSecondary
                  onClick={() => {
                    dispatch('CHOOSE_THING', { why: 'EventExplorer', allowNone: '(All)' })
                  }}
                >
                  {filteringByThing ? `Filtering by: ${filteringByThing.name}` : 'Filter by sticky'}
                </Button>}
              </div>
              <div className='bodge-switches'>
                {!queryObject.federatedUserId && <Switch
                  checked={thingId === '!!!null'}
                  onChange={v => {
                    const newThingId = v ? '!!!null' : undefined
                    vSave('ee-thingId', newThingId || null)
                    this.refreshLogic({ ...this.state, thingId: newThingId })
                  }}
                >
                  On stickies only
                </Switch>}
                {!queryObject.federatedUserId && <Switch
                  checked={onlyCurrentFederatedUser}
                  onChange={v => {
                    vSave('ee-onlyCurrentFederatedUser', v)
                    this.refreshLogic({ ...this.state, onlyCurrentFederatedUser: v })
                  }}
                >
                  By me only
                </Switch>}
              </div>
            </>
          )}
        </div>
        {foundByIdPayment && <Box className="link-box">
          <H1>Payment: {foundByIdPayment.consumerIdentifier}</H1>
          {foundByIdPayment.application && <p>{foundByIdPayment.application.name}</p>}
          <p>
            <Price price={foundByIdPayment.total} currency={foundByIdPayment.currency} />
          </p>
          {!foundByIdPayment.isUnpaid && <Time time={foundByIdPayment.sessionPaidAt} />}
          <div className='buttons'>
            <LinkButton to={`/me/payments/${foundByIdPayment.id}`} sameTab>Go to payment →</LinkButton>
            {(user.federatedUserCan('payment-refund') && foundByIdPayment.canRefund) && <Button
              isSecondary
              InlineIcon={dashboardIcons.goBack}
              onClick={() => dispatch('PAYMENT_REFUND', { user, why: 'EventExplorer', payment: foundByIdPayment })}
            >
              Refund
            </Button>}
          </div>
        </Box>}
        <div className='buttons'>
          {['SESSION_CART_PAY', 'SESSION_CART_PAY_FAIL'].includes(type) && <Button
            InlineIcon={dashboardIcons.search}
            listItems={[
              {
                id: 'find-by-sticky-id',
                name: 'Sticky ID',
                InlineIcon: dashboardIcons.search,
                onChoose: this.findPaymentId
              },
              {
                id: 'find-by-your-id',
                name: 'Reference',
                InlineIcon: dashboardIcons.search,
                onChoose: this.findPaymentUserPaymentId
              },
              {
                id: 'find-by-name',
                name: 'Name',
                InlineIcon: dashboardIcons.teamMember,
                onChoose: this.findPaymentName
              },
              {
                id: 'find-by-email',
                name: 'Email',
                InlineIcon: dashboardIcons.email,
                onChoose: this.findPaymentEmail
              }
            ]}
          >
            Find by
          </Button>}
          {EVENTS_WHICH_CAN_BE_DELETED.includes(type) && <SureButton
            InlineIcon={dashboardIcons.delete}
            backgroundColor='#ff3838'
            onClick={async () => {
              const body = {
                type,
                applicationId,
                startTime,
                endTime
              }
              log('[EventExplorer] [->deleteAll] body', body)
              try {
                dispatch('TRIGGER', { trigger: 'delete-events', body, showMessage: false })
                this.refreshLogic(this.state)
              } catch ({ message }) {
                dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
              }
            }}
          >
            Delete all
          </SureButton>}
          {user.federatedUserCan('events-download') && EVENTS_WHICH_CAN_BE_DOWNLOADED.includes(type) && <Button
            isSecondary
            InlineIcon={dashboardIcons.download}
            onClick={async () => {
              const {
                queryId,
                queryObject: propQueryObject
              } = this.props
              const queryObject = {
                startTime, endTime,
                ...propQueryObject,
                type
              }
              if (applicationId) {
                queryObject.applicationId = applicationId
              }
              log('[EventExplorer] [->download]', { queryId, queryObject })
              try {
                await window.sticky.session.events.getAllCsv(queryId, queryObject)
              } catch ({ message }) {
                dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
              }
            }}
          >
            Download CSV
          </Button>}
        </div>
        {currentSessionSummary && currentSessionSummary.size > 0 && (
          <Details user={user} className='summary' details={currentSessionSummary} />
        )}
        <div className='events'>
          {view === 'feed' && <Events>
            {currentSessionEvents.map((e, i) => (
              <Event
                key={e.id || `event-no-id-${i}`}
                {...dic}
                event={e}
              />
            ))}
          </Events>}
          {type === 'SESSION_CART_PAY' && view === 'table' && <EventsTable_SESSION_CART_PAY {...dic} events={currentSessionEvents} />}
        </div>
      </StyledEventExplorer>
    )
  }
}

EventExplorer.propTypes = {
  queryId: PropTypes.string.isRequired,
  queryObject: PropTypes.object.isRequired,
  thing: PropTypes.object,
  filterByType: PropTypes.bool,
  filterByApplication: PropTypes.bool,
  filterByThing: PropTypes.bool,
  filterByTime: PropTypes.bool,
  defaultValue: PropTypes.string,
  currentFederatedUserId: PropTypes.string
}
