/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../icons'
import { dispatch, subscribe } from '../../redux'
import { Button } from '@openbox-app-shared'
import ScrollableTable from '../../components/ScrollableTable'

import { CustomHelmet, Loading, Time } from '@openbox-app-shared'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--scrollable-table {
    td:nth-child(3) {
      min-width: 12rem; max-width: 12rem; width: 12rem;
    }
  }
`

const COLUMNS = {
  name: 'Reference',
  hint: 'Hint',
  createdAt: 'Created',
  actions: ''
}

function MyList({ user, vaultItems }) {
  const rows = vaultItems.map(_ => {
    return {
      name: <strong><code>{_.name}</code></strong>,
      createdAt: <Time time={_.createdAt} />,
      hint: _.hint,
      actions: (<>
        <Button
          title='Delete'
          backgroundColor='#ff3838'
          InlineIcon={dashboardIcons.delete}
          onClick={() => {
            dispatch('SURE_DELETE', { why: 'vaultItem', entity: _ })
          }}
        />
      </>)
    }
  })
  return (
    <ScrollableTable
      rows={rows}
      columns={COLUMNS}
    />
  )
}

function RenderChild({ context }) {
  const { user } = context.props
  const [vaultItems, setVaultItems] = useState()

  async function getVaultItems () {
    const _ = await window.sticky.users.vault.getAll()
    setVaultItems(_)
  }

  useEffect(
    () => {
      const subscriptions = [
        subscribe(
          'SURE_DELETE_GOOD',
          ({ why, entity }) => {
            why === 'vaultItem' && (() => {
              ; (async () => {
                try {
                  await window.sticky.users.vault.remove(entity)
                  await getVaultItems()
                } catch (e) {
                  dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
                }
              })()
            })()
          }
        )
      ]
      ;(async () => {
        try {
          await getVaultItems()
        } catch (e) {
          dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
        }
      })()
      return () => {
        subscriptions.forEach(s => s())
      }
    },
    []
  )

  return (
    <StyledComponent>
      <CustomHelmet title={`Vault | Account | ${user.name}`} />
      {!vaultItems && (
        <Loading />
      )}
      {vaultItems && <MyList user={user} vaultItems={vaultItems} />}
    </StyledComponent>
  )
}

const tab = {
  id: 'vault',
  name: (context) => 'Vault',
  inlineIcon: dashboardIcons.storeData,
  to: (context) => '/my/account/vault',
  child: props => <RenderChild {...props} />
}

export default tab
