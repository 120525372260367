import React from 'react'
import styled from 'styled-components'

import { ExpandCollapse, Input, DebouncedForm, UploadImage } from '@openbox-app-shared'
import dashboardIcons from '../../../icons'
import CopyUrl from '../../../components/CopyUrl'
import DeveloperBox from '../../../components/DeveloperBox'
import Box from '../../../components/Box'
import H2 from '../../../components/H2'
import Embedder from './Embedder'

const StyledComponent = styled.div`
  .component--box + .component--box {
    margin-top: 1rem;
  }
  .copy-url-container {
    max-width: 34rem;
  }
`

const tab = {
  id: 'deploy',
  name: (context) => 'Share',
  inlineIcon: dashboardIcons.deploy,
  to: (context) => `/me/flows/${context.state.application.id}/deploy`,
  child: function Child (context, extraProps) {
    const { application } = context.state
    const { user } = context.props
    const { viewSubroot } = context.props.match.params

    const liveLink = context.getLink(application, undefined, false, false)
    if (!liveLink) {
      return null
    }

    return (
      <StyledComponent>
        <Box className='copy-url-container'>
          <H2>Embed or link to</H2>
          <CopyUrl url={liveLink} buttonProps={{ backgroundColor: '#26de81' }} />
        </Box>
        <Box>
          <H2>Website pop up</H2>
          <Embedder
            application={application}
            previewLink={liveLink}
            onUpdate={application => {
              extraProps.debouncedProps.onChange({ application })
            }}
          />
        </Box>
        <DeveloperBox user={user} application={application} whichBox='goTo' developerModeHeader='Embed or link to for payments' developerModeCode={`${window.sticky.internals.urls.API}/go/flow/${application.id}?total=100&currency=${user.currency}&userPaymentId=REF1`} />
        <DeveloperBox user={user} application={application} whichBox='goTo' developerModeHeader='Xero' developerModeCode={`${window.sticky.internals.urls.API}/go/flow/${application.id}?total=[AMOUNTDUE]&currency=${user.currency}&userPaymentId=[INVOICENUMBER]`} />
      </StyledComponent>
    )
  }
}

export default tab
