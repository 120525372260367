/* eslint-disable quotes */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomHelmet } from '@openbox-app-shared'

import Dock from '../../components/Dock'
import _ from '../../_'

import { log } from '../../log'
import dashboardIcons from '../../icons'
import getLogOutUrl from '../../getLogOutUrl'
import { dispatch } from '../../redux'

const StyledRoute = styled.div`
  min-height: 100vh;
  iframe {
    display: block;
    width: 100%;
    height: calc(100vh - 76px);
  }
  .component--dock {
    width: fit-content;
    margin: 3px auto 0 auto;
    padding-bottom: 3px;
    > * {
      > svg {
        width: 2rem !important;
        height: 2rem !important;
        margin-bottom: 2px !important;
      }
    }
  }
`

export default class Route extends Component {
  constructor() {
    super()
    this.state = {}
    this.onMessage = this.onMessage.bind(this)
    this.onChooseDockItem = this.onChooseDockItem.bind(this)
  }

  onMessage (e) {
    if (e.data.event === 'HOW_TO_PAY') {
      this.onChooseDockItem('opsManagerV2')
      document.getElementById('no-ref--opsManagerV2').contentWindow.postMessage({ ...e.data }, '*')
    }
    if (e.data.event === 'HOW_TO_PAY_BACK') {
      this.onChooseDockItem('stickies-overview')
    }
    if (e.data.event === 'reloadProducts') {
      const { dockItems } = this.state
      dockItems.find(_ => _.id === 'opsManagerV2').shouldReloadNextTime = true
      this.setState({
        dockItems
      })
      return
    }
    if (e.data.event === 'autoUiLogOut') {
      window.sticky.users.logOut()
      const logOutRedirectTo = getLogOutUrl()
      window.location = logOutRedirectTo
      return
    }
    if (e.data.event === 'appSharedCardStage1') {
      try {
        window.stickyCardNumberReceived = object => {
          try {
            document.getElementById('no-ref--opsManagerV2').contentWindow.document.getElementById('ReduxHandler--PAY').contentWindow.postMessage({ event: 'stickyCardNumberReceived', message: object }, '*')
          } catch (e) {
            log('[Route-view-yeetStickyPay] try{} in stickyCardNumberReceived caught', e)
          }
        }
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
          const message = JSON.stringify({ action: 'readCardNumber' })
          window.ReactNativeWebView.postMessage(message)
        } else {
          log('[Route-view-yeetStickyPay] window.ReactNativeWebView is not available or postMessage is not a function')
        }
      } catch (error) {
        log('[Route-view-yeetStickyPay] error sending message to ReactNativeWebView:', error)
      }
      return
    }
  }

  async componentDidMount() {
    log('[Route-view-yeetStickyPay] [componentDidMount]', {})
    window.addEventListener('message', this.onMessage)

    const things = await window.sticky.things.getAll()
    const showThingsOverview = things.some(_ => !_.isVirtual)

    this.backupState = {
      backgroundColor: document.body.style.backgroundColor,
      backgroundImage: document.body.style.backgroundImage,
      main: document.querySelector('main').style.maxWidth,
      navbar: document.querySelector('.component--navbar').style.maxWidth
    }
    document.body.style.backgroundColor = 'black'
    document.body.style.backgroundImage = 'none'
    document.querySelector('main').style.maxWidth = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    const { user, autoUi } = this.props
    
    const dockItems = [
      user.federatedUserCan('payments') && {
        id: 'opsManagerV2',
        name: 'Pay',
        InlineIcon: dashboardIcons.pos,
        iframeUrl: `/ops-manager-2?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,logOut`,
        shouldReload: false
      },
      user.federatedUserCan('payments') && {
        id: 'payments',
        name: 'Paid',
        InlineIcon: dashboardIcons.check,
        iframeUrl: `/analytics/payments?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,logOut`,
        shouldReload: true
      },
      {
        id: 'links',
        name: 'Links',
        InlineIcon: dashboardIcons.link,
        iframeUrl: `/me/links?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,noApplicationLabel,noOpenedLabel,noAdd,logOut`,
        shouldReload: true
      },
      user.federatedUserCan('products') && {
        id: 'products',
        name: 'Products',
        InlineIcon: dashboardIcons.product,
        iframeUrl: `/me/products?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,logOut`,
        shouldReload: true
      },
      showThingsOverview && {
        id: 'stickies-overview',
        name: 'Overview',
        InlineIcon: dashboardIcons.thing,
        iframeUrl: `/stickies-overview?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,logOut`,
        shouldReload: false
      },
      user.federatedUserCan('master') && {
        id: 'teamMembers',
        name: 'Team',
        InlineIcon: dashboardIcons.teamMembers,
        iframeUrl: `/me/team?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,logOut`,
        shouldReload: false
      },
      // user.federatedUserCan('master') && {
      //   id: 'user',
      //   name: 'Account',
      //   InlineIcon: dashboardIcons.teamMember,
      //   iframeUrl: `/my/account/about?autoUi=${autoUi.includes('backToYeetYeet') ? 'backToYeetYeet,' : ''}noMainFrameAside,logOut`,
      //   shouldReload: false
      // },
      autoUi.includes('backToYeetYeet') && {
        id: 'exit',
        name: 'Exit',
        inlineIconString: window.sticky.internals.icons.get('arrowLeft'),
        // InlineIcon: dashboardIcons.void,
        goTo: '/sticky-pay'
      },
      autoUi.includes('logOut') && {
        id: 'exit',
        name: 'Log out',
        InlineIcon: dashboardIcons.logOut,
        onClick: () => {
          window.sticky.users.logOut()
          const logOutRedirectTo = getLogOutUrl()
          window.location = logOutRedirectTo
        }
      },
      autoUi.includes('browserBack') && {
        id: 'exit',
        name: 'Exit',
        inlineIconString: window.sticky.internals.icons.get('arrowLeft'),
        onClick: () => history.back()
      }
    ]
      .filter(_ => _)
    this.setState({
      dockItems
    })
  }

  async componentWillUnmount() {
    log('[Route-view-yeetStickyPay] [componentWillUnmount]')
    window.removeEventListener('message', this.onMessage)
    clearTimeout(this.stFree)
    document.body.style.backgroundColor = this.backupState.backgroundColor
    document.body.style.backgroundImage = this.backupState.backgroundImage
    document.querySelector('main').style.maxWidth = this.backupState.main
    document.querySelector('.component--navbar').style.display = this.backupState.navbar
  }

  onChooseDockItem (id) {
    const { dockItems } = this.state
    const foundDi = dockItems.find(_ => _.id === id)
    if (foundDi.goTo) {
      window.location = foundDi.goTo
      return
    }
    if (foundDi.onClick) {
      foundDi.onClick()
      return
    }
    function howToReload () {
      Array.from(document.querySelectorAll('iframe'))
        .forEach(_ => {
          _.style.display = 'none'
        })
      document.getElementById(`no-ref--${id}`).style.display = 'block'
    }
    if (foundDi.shouldReload || foundDi.shouldReloadNextTime) {
      dispatch('LOADING')
      this.stFree = setTimeout(
        () => {
          howToReload()
          dispatch('STOP_LOADING')
        },
        1 * 1000
      )
      document.getElementById(`no-ref--${id}`).src += ''
      if (foundDi.shouldReloadNextTime) {
        foundDi.shouldReloadNextTime = false
        this.forceUpdate()
      }
    } else {
      howToReload()
    }
  }

  render() {
    const { dockItems } = this.state
    log('[Route-view-yeetStickyPay] [render]', { dockItems })

    if (!dockItems) {
      return null
    }

    return (
      <StyledRoute
        className='level--styled-route'
      >
        <CustomHelmet
          title={window.sticky._('STICKY_PAY')}
        />
        {dockItems
          .filter(di => di.iframeUrl)
          .map((di, diI) => {
            return (
              <iframe style={{ display: diI === 0 ? 'block' : 'none' }} src={`${di.iframeUrl}&${window.location.search.substring(1)}`} key={`no-ref--${di.id}`} id={`no-ref--${di.id}`} />
            )
          })}

        <Dock
          items={dockItems}
          onChoose={this.onChooseDockItem}
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  onUpdateUser: PropTypes.func,
  onSaveUser: PropTypes.func,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
